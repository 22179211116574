import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../store/slice/authSlice';
import { useQuery } from 'react-query';
import { getClient, getMailQueues } from '../../api/get';

const SubHeader = ({ setChosenMenuItem, chosenMenuItem }) => {
	const user = useSelector((state) => state.auth.user);

	const clientQuery = useQuery(['clients', user.userId], getClient);

	const dispatch = useDispatch();

	const { data } = useQuery('mail_queues', getMailQueues);

	return (
		<div className="subheader fixed-menu2">
			<div className="container">
				<div className="panel">
					<div className="panel__inner">
						<div className="panel__title">Twoje konto</div>
						<div className="panel__nav">
							<a
								onClick={(e) => {
									e.preventDefault();
									setChosenMenuItem('Pobrane materiały');
								}}
								href="/"
								className="panel__item"
							>
								<i className="ico ico-user-icon"></i>
								<span className="panel__item-name">
									Zalogowany jako:{' '}
									<span className="yellow">{clientQuery.data?.nick}</span>
								</span>
							</a>
							<a
								href="/"
								className="panel__item hidden-sm"
								onClick={(e) => {
									e.preventDefault();
									setChosenMenuItem('Pomoc');
								}}
							>
								<i className="ico ico-help-icon"></i>
								<span className="panel__item-name">Pomoc</span>
							</a>

							{chosenMenuItem === 'Twoja biblioteka' ? (
								<a
									onClick={(e) => {
										e.preventDefault();
										setChosenMenuItem('Pobrane materiały');
									}}
									href="/"
									className="panel__item hidden-sm"
								>
									<i className="ico ico-library-icon"></i>
									<span className="panel__item-name">Moje pobrane obrazy</span>
								</a>
							) : (
								<a
									onClick={(e) => {
										e.preventDefault();
										setChosenMenuItem('Twoja biblioteka');
									}}
									href="/"
									className="panel__item hidden-sm"
								>
									<i className="ico ico-library-icon"></i>
									<span className="panel__item-name">Twoja biblioteka</span>
								</a>
							)}

							<a
								href="/"
								className="panel__item "
								onClick={(e) => {
									e.preventDefault();
									setChosenMenuItem('Powiadomienia');
								}}
							>
								{!!data?.length && (
									<span className="label-icon">
										<i className="ico ico-bell-icon"></i>
										<span className="label">{data?.length}</span>
									</span>
								)}

								<span className="panel__item-name hidden-sm">
									Powiadomienia
								</span>
							</a>
							<a
								href="/"
								onClick={(e) => {
									e.preventDefault();
									dispatch(authActions.logOut());
								}}
								className="panel__item hidden-sm"
							>
								<i className="ico ico-log-off"></i>
								<span className="panel__item-name">Wyloguj</span>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SubHeader;
